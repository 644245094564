export default {
  headTitle: 'Fangzheng Hongyun Platform Registration',
  back: 'Back to Login Page',
  tipRegister: 'You are registering as',
  tipIs: 'of the',
  roleStudent: 'Scholar',
  roleAuthor: 'Author',
  sendCode: 'Send Verification Code',
  sendedCode: 'Sent',
  hasAccount1: 'Email detected to have an account registered on Fangzheng Cloud Platform',
  hasAccount2: ', you can directly',
  hasAccount3: 'Log In',
  hasAccount4: 'login with SMS.',
  read1: 'Read and accept',
  read2: 'User Agreement',
  read3: 'and',
  read4: 'Privacy Policy',
  readError: 'Please confirm acceptance of the User Agreement and Privacy Policy',
  submit: 'Submit Registration',
  confirm: 'Confirm',
  accountError: 'Account creation failed, please try again later.',
  imgChange: 'Can’t see it clearly? Change image',
  selectTrue: 'Please select the correct answer',
  dialog: {
    tipTitle: 'Tip',
    validTitle: 'Image Verification'
  },

  form: {
    realName: 'Real Name',
    realNameTip: 'Please enter your real name',
    email: 'Email',
    emailTip: 'Please enter your email address',
    emailCode: 'Email Verification Code',
    emailCodeTip: 'Please enter the email verification code',
    emailCodeError: 'Incorrect email verification code',
    backupEmail: 'Backup Email',
    backupEmailTip: 'Please enter your backup email',
    phone: 'Phone Number',
    phoneTip: 'Please enter your phone number',
    verifyCode: 'Phone Verification Code',
    verifyCodeTip: 'Please enter the phone verification code',
    verifyCodeError: 'Incorrect phone verification code',
    setPassword: 'Set Password',
    setPasswordTip: 'Please set your login password',
    passwordRule:
        'Must be 8-20 characters long, must include uppercase letters, lowercase letters, and numbers. No punctuation, spaces, or Chinese characters allowed.',
    confirmPassword: 'Confirm Password',
    confirmPasswordTip: 'Please re-enter your login password'
  },

  rule: {
    passwordNotNull: 'Password cannot be empty',
    passwordNotPass: 'Password does not meet requirements',
    passwordNotSame: 'Passwords do not match.',
    realNameRequired: 'Please enter your real name',
    emailRequired: 'Please enter your email address',
    emailError: 'Incorrect email format',
    emailCodeRequired: 'Please enter the email verification code',
    phoneRequired: 'Please enter your phone number',
    phoneError: 'Incorrect phone number format',
    verifyCodeRequired: 'Please enter the phone verification code'
  }
}
