<!--
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-12 16:59:47
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/App.vue
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    components: {},
    created() {},
    mounted() {
      // window.addEventListener(
      //   "error",
      //   error => {
      //     // token过期，资源加载失败，刷新页面
      //     if (error.target.nodeName === "SCRIPT") {
      //       window.location.reload()
      //     }
      //   },
      //   true
      // )
    },
    computed: {
      // menus() {
      //   return this.$store.getters.authedMenus
      // },
    }
  }
</script>
