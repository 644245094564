import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cnMessage from './cn'
import enMessage from './en'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'cn', // 设置语言环境
  fallbackLocale: 'cn',
  messages: {
    cn: cnMessage,
    en: enMessage
  }
})
export { i18n }
