/*
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-11 15:05:13
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/store/modules/common.js
 */
const getType = obj => {
  return Object.prototype.toString.call(obj)
}
export default {
  state: {
    userInfor: {
      name: '',
      roles: []
    }
  },
  getters: {
    authedMenus: state => {
      return state.menus1.filter(item => item.isAuth)
    }
  },
  mutations: {
    // 更新面包屑
    update_breadcrumb(state, payload) {
      if (getType(payload) === '[object Array]') {
        state.breadcrumb = [...payload]
      }
    }
  }
}
