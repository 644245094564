<!--
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-12 17:04:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/layout/Common.vue
-->
<template>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</template>

<script>
  // import Header from "@/components/common/Header.vue"
  // import LeftNav from "@/components/common/LeftNav.vue"
  export default {
    components: {
      // Header,
      // LeftNav
    },
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {},
    beforeDestroy() {}
  }
</script>

<style lang="scss" scoped></style>
