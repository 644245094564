export default {
  headTitle: '方正鸿云平台注册',
  back: '返回登录页',
  tipRegister: '您正在注册为',
  tipIs: '的',
  roleStudent: '学者',
  roleAuthor: '作者',
  sendCode: '发送验证码',
  sendedCode: '已发送',
  hasAccount1: '检测到邮箱在方正云平台注册过账号',
  hasAccount2: ', 可直接',
  hasAccount3: '登录',
  hasAccount4: '手机短信登录。',
  read1: '阅读并接受',
  read2: '用户协议',
  read3: '和',
  read4: '隐私政策',
  readError: '请确认接受用户协议和隐私政策',
  submit: '提交注册',
  confirm: '确认',
  accountError: '账号创建失败，请稍后重试。',
  imgChange: '看不清？换一张',
  selectTrue: '请选择正确答案',
  dialog: {
    tipTitle: '提示',
    validTitle: '图形验证'
  },

  form: {
    realName: '真实姓名',
    realNameTip: '请填写真实姓名',
    email: '邮箱',
    emailTip: '请输入邮箱地址',
    emailCode: '邮箱验证码',
    emailCodeTip: '请输入邮箱验证码',
    emailCodeError: '邮箱验证码错误',
    backupEmail: '备用邮箱',
    backupEmailTip: '请输入备用邮箱',
    phone: '手机号码',
    phoneTip: '请输入手机号码',
    verifyCode: '手机验证码',
    verifyCodeTip: '请输入手机验证码',
    verifyCodeError: '手机验证码错误',
    setPassword: '设置密码',
    setPasswordTip: '请设置登录密码',
    passwordRule:
      '长度为8-20个字符，必须包含大写字母、小写字母和数字，不允许有标点符号、空格和中文。',
    confirmPassword: '确认密码',
    confirmPasswordTip: '请再次输入登录密码'
  },

  rule: {
    passwordNotNull: '密码不能为空',
    passwordNotPass: '密码组合不符合要求',
    passwordNotSame: '登录密码不一致。',
    realNameRequired: '请填写真实姓名',
    emailRequired: '请填写邮箱地址',
    emailError: '邮箱格式错误',
    emailCodeRequired: '请填写邮箱验证码',
    phoneRequired: '请填写手机号',
    phoneError: '手机号格式错误',
    verifyCodeRequired: '请填写手机验证码'
  }
}
