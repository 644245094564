/*
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-13 15:00:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import CommonLayout from '@/layout/Common.vue'
// import { Message } from "element-ui"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: CommonLayout,
    children: [
      {
        path: '/register',
        component: () => import('@/views/register/index.vue')
      },
      {
        path: '/agree',
        name: 'agree',
        component: () => import('@/views/register/agree.vue')
      }
    ]
  }
  // {
  //   path: "*",
  //   component: () => import("@/components/common/404.vue")
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import { i18n } from '../lang/i18n'
router.beforeEach((to, from, next) => {
  if (to.query?.lang) {
    const mapCode = {
      en: 'en',
      'zh-CN': 'cn'
    }
    i18n.locale = mapCode[to.query?.lang] || to.query?.lang
  }
  next()
})
// router.onError(error => {
//   // console.log("load error", error)
//   const pattern = /Loading chunk ([\w\W])+ failed/g
//   const isChunkLoadFailed = error.message.match(pattern)
//   if (isChunkLoadFailed) {
//     Message.error({
//       message: "登录已过期，请重新登录"
//     })
//     setTimeout(() => {
//       window.location.href = "/oauth/logout?redirect=" + location.href
//     }, 1000)
//   }
// })
export default router
